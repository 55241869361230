import { createContext } from 'react';
import { Auth0Client, User as Auth0User } from '@auth0/auth0-spa-js';
import { Auth0Role } from '@/api/graphql';

export type AuthenticationContextType = {
  client?: Auth0Client;
  user?: Auth0User;
  isRegisteredViaSocialProvider: boolean;
  isAuthenticated: boolean;
  claims?: {
    organizationId: string;
    userId: string;
    auth0Role: Auth0Role;
    logging?: boolean;
  };
  jwtToken?: string;
  auth0OrgId?: string;
  loggingEnabled: boolean;
};

export const initialValue: AuthenticationContextType = {
  isRegisteredViaSocialProvider: false,
  isAuthenticated: false,
  jwtToken: undefined,
  auth0OrgId: undefined,
  loggingEnabled: false,
};

export const AuthenticationContext =
  createContext<AuthenticationContextType>(initialValue);
