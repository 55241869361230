import { WatchQueryFetchPolicy } from '@apollo/client';

export const UrlRegex =
  /(?:http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,}\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/;

export const DefaultGuid = '00000000-0000-0000-0000-000000000000';

export const supportedImageMimeTypes =
  'image/png, image/jpeg, image/x-icon, image/gif, image/webp';

export const scDomainRegex = /^sc-domain:/;

export const defaultWatchQueryOptions: {
  fetchPolicy: WatchQueryFetchPolicy;
  nextFetchPolicy: WatchQueryFetchPolicy;
} = {
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-only',
};

export enum RoleIds {
  ADMIN = '00000000-0000-0000-0000-00000000000',
  MEMBER = '00000000-0000-0000-0000-00000000001',
  EDITOR = '00000000-0000-0000-0000-00000000002',
  EXTERNAL_WRITER = '00000000-0000-0000-0000-00000000003',
  ANALYST = '00000000-0000-0000-0000-00000000004',
  CLIENT = '00000000-0000-0000-0000-00000000005',
}

// 13/08/2022
export const euDateRegex =
  /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/;
// 08/13/2022
export const usDateRegex =
  /^(0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/]\d{4}$/;

// e.g. 2022-07-14
export const timeRangeRegex =
  /^\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/;

export const timeRangeStringFormat = 'yyyy-MM-dd';

export const englishMeeting = 'https://meetings.hubspot.com/dan1005';
export const germanMeetingUrl =
  'https://meetings.hubspot.com/roland50/30-minuten-meeting';
